onGoogleMapsLoad = () => {
    let maps = document.querySelectorAll('.js-map');
    maps.forEach(item => {
        let location = {
            latlng: {
                lat: parseFloat(item.dataset.lat),
                lng: parseFloat(item.dataset.lng)
            }
        };
        console.log(location);
        let map = new google.maps.Map(item, {
            zoom: parseInt(item.dataset.mapZoom, 10),
            center: location.latlng,
            scrollwheel: false
          });

        mapMarkerForLocation(location, map);
    })
};

mapMarkerForLocation = (location, map) => {
    // noinspection JSUnresolvedVariable
    return new google.maps.Marker({
        position: location.latlng,
        title: location.name,
        map: map,
        animation: google.maps.Animation.DROP
    });
};
